import { reactive, ref } from "vue";
import { ScrollToTop } from "@/common/utils";
import { FormatterDateTime } from "@/common/formatter";
import { GetShopAfterSaleArgs, ShopAfterSalesList } from "@/api/OrdersApi";
import { useRouter } from "vue-router";
export default function () {
    const router = useRouter();
    const showHistoryDlg = ref(false);
    const datas = reactive(new Array());
    const totalCount = ref(0);
    const queryArgs = reactive(new GetShopAfterSaleArgs());
    const changeTime = (val) => {
        queryArgs.ctimeStart = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
        queryArgs.ctimeEnd = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
    };
    const queryData = async () => {
        const res = await ShopAfterSalesList(queryArgs);
        if (!res.errno) {
            datas.splice(0, datas.length);
            const list = res.data.list;
            list.forEach(item => {
                datas.push(item);
            });
            totalCount.value = res.data.total;
        }
        ScrollToTop();
    };
    const toOrderList = (orderId) => {
        window.open('/#/orders?orderId=' + orderId);
    };
    return {
        datas,
        totalCount,
        queryData,
        queryArgs,
        showHistoryDlg,
        changeTime,
        toOrderList,
    };
}
