import { ShopAfterSaleStatus } from '@/common/enum';
import { OrderGet } from './OrderRequest';
// 店铺售后单列表参数
export class GetShopAfterSaleArgs {
    constructor() {
        this.status = ShopAfterSaleStatus.All;
        this.page = 1;
        this.pageSize = 10;
    }
}
// 店铺售后单列表返回数据
export class ShopAfterSaleListResponse {
}
// 店铺售后单
export function ShopAfterSalesList(data) {
    return OrderGet(`/merchantshop/aftermarket/list?page=${data.page}&pageSize=${data.pageSize}&applyId=${data.applyId || ''}&orderId=${data.orderId || ''}&uid=${data.uid || ''}&status=${data.status || ''}&ctimeStart=${data.ctimeStart || ''}&ctimeEnd=${data.ctimeEnd || ''}&storeId=${data.storeId || ''}`);
}
